import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";


import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";

import PhotoPlacement from "../../assets/imgs/photo-placement.jpg";
import AdvCard from "../../components/AdvCard";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { getImageURL } from "../../helpers/all";
import { getUserProducts } from "../../services/product";
import { getSubscriptions } from "../../services/subscription";
import NavPagination from "../../components/NavPagination";
import useIsMobile from "../../hooks/isMobile";


const Profile = () => {
  const isMobile = useIsMobile('1399px')
  const user = useSelector((state) => state.auth.user);
  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const [subscriptions, setSubscriptions] = useState({
    loading: true,
    items: [],
  });
  const [currentPage, setCurrentPage] = useState(1)
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  useEffect(() => {

    getUserProducts({ status: 1, page: currentPage, size: 12 })
      .then((res) => {
        setProducts((prev) => ({
          ...prev,
          loading: false,
          items: res.products.items,
          pagination: res.products.pagination,
          count: res.countAds

        }))
      })
      .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
    getSubscriptions({ to: true, size: isMobile ? 6 : 8 })
      .then((res) => {
        setSubscriptions((prev) => ({
          ...prev,
          loading: false,
          items: res.items,
          pagination: res.pagination,

        }))
      })
      .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [currentPage]);
  return (
    <section className="sec-profile">
      <Meta title="Профиль" />
      <ReturnTitle link="/account" title="Профиль" />

      <Row className="gx-3 gx-xl-4">
        <Col md={4} className="mb-4 mb-md-0">
          <h4 className="text-center">

            <span className="mx-1">
              {
                (user?.firstName)
                  ? user?.firstName
                  : "Фамилия Имя Отчество"
              }
            </span>
          </h4>

          {
            (user?.media) ?
              <img
                src={getImageURL({
                  path: user?.media,
                  size: "mini",
                  type: "user",
                })
                }
                alt="userphoto"
                className="photo"
              />
              : <img
                src={PhotoPlacement}
                alt="userphoto"
                className="photo"
              />
          }

          <div className="box p-2 p-xl-3 mt-4">
            <div className="fs-08 d-xl-flex justify-content-between align-items-center mb-3">
              <p>Подписки <span className="gray ms-1">{subscriptions?.pagination?.totalItems}</span></p>
              <Link to="/account/partners" className="link mt-2 mt-xl-0">Добавить</Link>
            </div>

            <ul className="list-unstyled row row-cols-4 row-cols-md-3 row-cols-xxl-4 g-2">
              {subscriptions?.items?.length > 0 && subscriptions?.items.map(item =>
                <li>
                  <Link to={'/user/' + item?.to?.uid} className="partner">
                    <img src={getImageURL({ path: item?.to?.media, size: "mini", type: "user" })} alt="Саша Петров" />
                    <h6>{item?.to?.firstName}</h6>
                  </Link>
                </li>
              )}
            </ul>
          </div>

        </Col>
        <Col md={8}>
          <div className="box p-3 fs-08">
            <Link to='/account/settings' className="d-block link mb-3">Редактировать информацию</Link>
            <table className="table table-borderless table-sm mb-0">
              <tbody>
                {user?.nickname ?
                  <>
                    <tr>
                      <td>ID:</td>
                      <td>{parseInt(user?.id) + 1000}</td>
                    </tr>
                    <tr>
                      <td>Никнейм:</td>
                      <td>{user?.uid}</td>
                    </tr>
                  </>
                  :
                  <tr>
                    <td>ID:</td>
                    <td>{user?.uid}</td>
                  </tr>
                }
                {(user.data?.type == "llc" || user.data?.type == "ip") &&
                  <tr>
                    <td>Компания:</td>
                    <td>{user?.data?.companyName}</td>
                  </tr>
                }
                <tr>
                  <td>Дата регистрации:</td>
                  <td>{moment(user?.createdAt).format("DD.MM.YYYY")}</td>
                </tr>
                {(user?.data?.type != "llc" && user?.data?.type != "ip") &&
                  <tr>
                    <td>Дата рождения:</td>
                    <td>{(user?.birthday) ? moment(user?.birthday).format("DD.MM.YYYY") : '―'}</td>
                  </tr>
                }
                <tr>
                  <td>Город:</td>
                  <td>{user?.data?.city}</td>
                </tr>
                <tr>
                  <td>Номер телефона:</td>
                  <td>{(user?.phone) ? user?.phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/, "+7 ($2) $3-$4-$5") : '―'}</td>
                </tr>
                <tr>
                  <td>Адрес эл. почты:</td>
                  <td>{(user?.email) ? user?.email : '―'}</td>
                </tr>
                {user.data?.type != "individual" &&
                  <tr>
                    <td>ИНН:</td>
                    <td>{user?.data?.INN}</td>
                  </tr>
                }
                {user.data?.type == "ip" &&
                  <tr>
                    <td>ОГРНИП:</td>
                    <td>{user?.data?.OGRNIP}</td>
                  </tr>
                }
                {user.data?.type == "llc" &&
                  <tr>
                    <td>ОГРН:</td>
                    <td>{user?.data?.OGRN}</td>
                  </tr>
                }
                {user.data?.type == "llc" &&
                  <tr>
                    <td>Юридический адрес:</td>
                    <td>{user?.data?.legalAddress}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <div className="box p-2 p-sm-3 fs-08 mt-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>Объявления <span className="gray ms-1">{products?.count ?? 0}</span></div>
              {/* <button type="button" className="color-1">Показать все</button> */}
            </div>
            {products?.items?.length > 0 ?
              <ul className="list-unstyled row row-cols-2 row-cols-xxl-3 g-2 g-sm-3">

                {products.items.map(item =>
                  <li>
                    <AdvCard {...item} />
                  </li>
                )}
              </ul>

              :
              <h3 className="text-center">Нет объявлений.</h3>
            }
            <div className="mt-3" >
              <NavPagination totalPages={products?.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
            </div>

          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Profile;
