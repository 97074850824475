import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { getImageURL } from '../../helpers/all';
import { useSelector } from 'react-redux';
import PhotoPlacement from "../../assets/imgs/photo-placement.jpg";
import { MdDeleteForever } from "react-icons/md";

const DialogPreview = ({ id, to, from, message, messagecount }) => {
  const userId = useSelector((state) => state.auth?.user?.id);
  const user =
    userId == to?.id
      ? from
      : to;

  // const [elapsedTime, setElapsedTime] = useState('');

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const timePassed = moment(message.createdAt).fromNow();
  //     setElapsedTime(timePassed);
  //   }, 1000);
  //   return () => clearInterval(interval); // Очистить интервал перед перемонтированием компонента
  // }, [message.createdAt]);

  const imag = getImageURL({ path: user?.media, type: "user" })

  return (
    <Link to={`${id}`} className="dialog-preview">
      <div className="img">
        {
          user.media
            ? <img src={imag} alt="user" />
            : <img src={PhotoPlacement} alt="user" />
        }
        {
          user.online.status
            ? <div className="indicator active"></div>
            : <div className="indicator"></div>
        }
      </div>

      <div className="text">
        <div className='d-flex justify-content-between align-items-center mb-1'>
          <div className="d-flex align-items-baseline">
            <h6>{user.firstName}</h6>
            {/* <time>{elapsedTime}</time> */}
          </div>
          {/* <button type='button' className='del'>
            <MdDeleteForever />
          </button> */}
        </div>
        <div className="d-flex">
          {messagecount ? <div className="count">{messagecount}</div> : ""}
          <p className='flex-1 ms-2'>{message.text ?? "Нет сообщений"}</p>
        </div>
      </div>
    </Link>
  );
};

export default DialogPreview;