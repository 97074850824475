import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { MdChatBubbleOutline, MdOutlinePersonAddAlt1 } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import PhotoPlacement from "../assets/imgs/photo-placement.jpg";
import AdvCard from "../components/AdvCard";
import Meta from "../components/Meta";
import { getImageURL } from '../helpers/all';
import useIsMobile from '../hooks/isMobile';
import { getUser, getUserAuth } from '../services/user';
import AccountMenu from './account/AccountMenu';
import { editSubscription, getSubscriptions } from '../services/subscription';
import Loader from '../components/utils/Loader';
import { getFavorites, toggleFavorite } from '../services/favorite';
import NavPagination from '../components/NavPagination';
import { useForm, useWatch } from 'react-hook-form';
import Textarea from '../components/utils/Textarea';
import { FiInfo } from 'react-icons/fi';
import { NotificationManager } from 'react-notifications';
import { createTask } from '../services/task';

const ViewProfile = () => {
    const isMobile = useIsMobile('991px')
    const isAuth = useSelector((state) => state?.auth.isAuth);
    const { id } = useParams();
    const userId = useSelector(state => state.auth?.user?.id);
    const dispatch = useDispatch();
    const [user, setUser] = useState({
        data: {},
        loading: true,
    });
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const [showAlert, setShowAlert] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const {
        control,
        register,
        formState: { errors, isValid },
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onSubmit",
        defaultValues: { userId: id },
    });
    const data = useWatch({ control });
    const handleSubmitComplaint = () => {
        if (!data?.title) {
            return NotificationManager.error(
                "Небоходимо выбрать тему жалобы"
            )
        }
        setSubmitted(true);
        onTask()
    };
    const onTask = useCallback(() => {
        createTask({ type: "report", ...data })
            .then(() => {
                NotificationManager.success("Жалоба отправлена");

            })
            .catch((err) => {
                NotificationManager.error(
                    err?.response?.data?.error ?? "Ошибка при отправке"
                );
            });
    }, [data]);
    const getPage = () => {
        if (isAuth) {
            getUserAuth({ id, page: currentPage, size: 12, status: 1 })
                .then((res) => {
                    res && setUser({
                        loading: false,
                        data: res.user,
                        products: res.products,
                    })

                })
                .catch(() => setUser((e) => ({ ...e, loading: false })));
        }
        else {
            getUser({ id, page: currentPage, size: 12, status: 1 })
                .then((res) => {
                    res && setUser({
                        loading: false,
                        data: res.user,
                        products: res.products,
                    })

                })
                .catch(() => setUser((e) => ({ ...e, loading: false })));

        }
    }
    useEffect(() => {
        getPage()


    }, [id, currentPage]);
    const onSub = useCallback(() => {
        editSubscription({ to: user.data.id })
            .then((res) => {
                res && getPage()
            })

    }, [user]);
    useEffect(() => {
        if (isAuth) {
            dispatch(getFavorites());
        }
    }, []);
    const onFav = useCallback((e) => {
        dispatch(toggleFavorite({ productId: e, }));
    }, []);
    if (user?.loading) {
        return <Loader full />;
    }
    return (
        <main className='account'>
            <Meta title={"Профиль пользователя " + (user?.data?.nickname ?? user?.data?.uid) + " на сайте бесплатных предпринимателей Бизнес My Life"} description={"Посмотрите профиль и объявления пользователя " + (user?.data?.nickname ?? user?.data?.uid) + " на сайте бесплатных предпринимателей Бизнес My Life"} />
            <Container className='account-container'>
                {(!isMobile)
                    && isAuth ? <AccountMenu /> :
                    <nav className='account-nav d-none d-lg-block'>
                        <ul>
                            <li>
                                <Link to="/login" className='active'>Войти в личный кабинет</Link>
                            </li>
                        </ul>
                    </nav>

                }

                {/* <AccountMenu/> */}
                <div className="account-main">
                    <section className="sec-profile">
                        <Row className="gx-3 gx-xl-4">
                            <Col md={4} className="mb-4 mb-md-0">
                                <h4 className="text-center">{
                                    (user?.data?.firstName)
                                        ? user?.data?.firstName
                                        : "Фамилия Имя Отчество"
                                }</h4>
                                {
                                    (user?.data?.media) ?
                                        <img
                                            src={getImageURL({
                                                path: user?.data?.media,
                                                size: "mini",
                                                type: "user",
                                            })
                                            }
                                            alt="userphoto"
                                            className="photo"
                                        />
                                        : <img
                                            src={PhotoPlacement}
                                            alt="userphoto"
                                            className="photo"
                                        />
                                }
                                {userId ? userId != user?.data?.id &&
                                    <div className="box p-3 mt-4">
                                        <Link to="/account/messages/newMessage" state={user?.data} className='link'>
                                            <MdChatBubbleOutline className='fs-13 me-2' />
                                            <span>Написать сообщение</span>
                                        </Link>
                                        <hr className='my-3' />
                                        <button type='button' className='link' onClick={() => onSub()}>
                                            <MdOutlinePersonAddAlt1 className='fs-13 me-2' />
                                            {user?.data?.subscriptionStatus ?
                                                <span>Отписаться</span>
                                                :
                                                <span>Подписаться</span>
                                            }
                                        </button>
                                        <hr className='my-3' />
                                        <button type='button' className='link' onClick={() => setShowAlert(true)}>
                                            <FiInfo className='fs-13 me-2' />
                                            <span>Пожаловаться</span>
                                        </button>

                                    </div>
                                    :
                                    <div className="box p-3 mt-4">
                                        <Link to="/login" className='link'>
                                            <MdChatBubbleOutline className='fs-13 me-2' />
                                            <span>Написать сообщение</span>
                                        </Link>
                                        <hr className='my-3' />
                                        <Link to="/login" className='link'>
                                            <MdOutlinePersonAddAlt1 className='fs-13 me-2' />
                                            <span>Подписаться</span>
                                        </Link>
                                        <hr className='my-3' />
                                        <Link to="/login" className='link'>
                                            <FiInfo className='fs-13 me-2' />
                                            <span>Пожаловаться</span>
                                        </Link>

                                    </div>
                                }
                            </Col>
                            <Col md={8}>
                                <div className="box p-3 fs-08">
                                    <table className="table table-borderless table-sm mb-0">
                                        <tbody>
                                            {user?.data?.nickname ?
                                                <>
                                                    <tr>
                                                        <td>ID:</td>
                                                        <td>{parseInt(user?.data?.id) + 1000}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Никнейм:</td>
                                                        <td>{user?.data?.uid}</td>
                                                    </tr>
                                                </>
                                                :
                                                <tr>
                                                    <td>ID:</td>
                                                    <td>{user?.data?.uid}</td>
                                                </tr>
                                            }
                                            {(user?.data?.data?.type == "llc" || user?.data?.data?.type == "ip") &&
                                                <tr>
                                                    <td>Компания:</td>
                                                    <td>{user?.data?.data?.companyName}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>Дата регистрации:</td>
                                                <td>{moment(user?.data?.createdAt).format("DD.MM.YYYY")}</td>
                                            </tr>
                                            {(user?.data?.data?.type != "llc" && user?.data?.data?.type != "ip") &&
                                                <tr>
                                                    <td>Дата рождения:</td>
                                                    <td>{(user?.data?.birthday) ? moment(user?.data?.birthday).format("DD.MM.YYYY") : '―'}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>Город:</td>
                                                <td>{user?.data?.data?.city}</td>
                                            </tr>
                                            <tr>
                                                <td>Номер телефона:</td>
                                                <td>{(user?.data?.phone) ? "скрыто" : '―'}</td>
                                            </tr>
                                            <tr>
                                                <td>Адрес эл. почты:</td>
                                                <td>{(user?.data?.email) ? "скрыто" : '―'}</td>
                                            </tr>
                                            {user?.data?.data?.type != "individual" &&
                                                <tr>
                                                    <td>ИНН:</td>
                                                    <td>{user?.data?.data?.INN}</td>
                                                </tr>
                                            }
                                            {user?.data?.data?.type == "ip" &&
                                                <tr>
                                                    <td>ОГРНИП:</td>
                                                    <td>{user?.data?.data?.OGRNIP}</td>
                                                </tr>
                                            }
                                            {user?.data?.data?.type == "llc" &&
                                                <tr>
                                                    <td>ОГРН:</td>
                                                    <td>{user?.data?.data?.OGRN}</td>
                                                </tr>
                                            }
                                            {user?.data?.data?.type == "llc" &&
                                                <tr>
                                                    <td>Юридический адрес:</td>
                                                    <td>{user?.data?.data?.legalAddress}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="box p-2 p-sm-3 fs-08 mt-4">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>Объявления <span className="gray ms-1">{user?.products?.pagination?.totalItems ?? 0}</span></div>
                                        {/* <button type="button" className="color-1">Показать все</button> */}
                                    </div>
                                    {user?.products?.items?.length > 0 ?
                                        <ul className="list-unstyled row row-cols-2 row-cols-xxl-3 g-2 g-sm-3">

                                            {user?.products.items.map(item =>
                                                <li>
                                                    <AdvCard {...item} onFav={onFav} />
                                                </li>
                                            )}
                                        </ul>

                                        :
                                        <h3 className="text-center">Нет объявлений.</h3>
                                    }
                                    <div className="mt-3" >
                                        <NavPagination totalPages={user?.products?.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </Container>
            <Modal show={showAlert} onHide={handleCloseAlert} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className="mb-3">Пожаловаться</h4>
                    {!submitted ? (
                        <div>
                            <div className="mb-4">
                                <select value={data.title} onChange={e => setValue('title', e.target.value)} className="mb-3">
                                    <option value="">Выберите тему жалобы</option>
                                    <option value="Спам">Спам</option>
                                    <option value="Мошенник">Мошенник</option>
                                    <option value="Нецензурная лексика">Нецензурная лексика</option>
                                    <option value="Неблагоприятный контент">Неблагоприятный контент</option>
                                    <option value="Ненастоящие данные профиля">Ненастоящие данные профиля</option>
                                    <option value="Другое">Другое</option>
                                </select>
                                <Textarea
                                    className="col-md-6"
                                    type={"text"}
                                    label={"Описание"}
                                    onChange={e => setValue("comment", e)}
                                />
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button onClick={handleSubmit(handleSubmitComplaint)} className="mr-3">Отправить жалобу</Button>
                                <Button onClick={handleCloseAlert} className="mr-3">Закрыть</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="mb-3 text-success">
                            Жалоба отправлена!
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </main>
    )
}

export default ViewProfile