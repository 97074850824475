import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/utils/Input";
import { login } from "../../services/auth";
import { FaTelegramPlane } from "react-icons/fa";
import TelegramLoginButton from 'telegram-login-button';
import useIsMobile from "../../hooks/isMobile";
import socket from "../../config/socket";

const Login = () => {
  const isAuth = useSelector((state) => state?.auth?.isAuth);
  const status = useSelector((state) => state?.auth?.user?.data?.status);
  const [captcha, setCaptcha] = useState(false);
  const navigate = useNavigate();
  const botUsername = "BMLPRO1_bot"
  const isMobile = useIsMobile('991px')

  useEffect(() => {
    if (isAuth) {
      if (status) {
        return navigate("/account/profile");
      }
      else {
        return navigate("/account/settings");
      }
    }
  }, [isAuth]);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });

  const dispatch = useDispatch();

  const onSubmit = useCallback((data) => {
    dispatch(login(data));
  }, []);
  const handleTelegramResponse = (res) => {

    const telegramUrl = `https://t.me/${botUsername}?start=`;
    // socket.on('auth/' + key, (data) => {
    //   console.log(data)
    //   if (data.user) {
    //     dispatch(login({ data: data.user }));
    //   }
    // })

    window.open(telegramUrl, '_blank');
    navigate("/telegram/login")
  };

  return (
    <main>
      <Meta title="Авторизация" />
      <Container>
        <section className="py-4 py-sm-5 mb-6">
          <h1 className="large text-center mb-4">ВХОД</h1>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  className="mb-4"
                  type="login"
                  label="E-mail"
                  name="login"
                  placeholder="E-mail"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Введите имя пользователя или email",
                    maxLength: {
                      value: 250,
                      message: "Максимально 250 символов",
                    },
                  }}
                />
                <Input
                  label="Пароль"
                  type="password"
                  name="password"
                  errors={errors}
                  placeholder="Пароль"
                  register={register}
                  validation={{
                    required: "Введите пароль",
                    minLength: {
                      value: 4,
                      message:
                        "Минимальный пароль должен состоять из 4-ех символов",
                    },
                  }}
                />

                {/* <ReCAPTCHA
                  className="mt-4 d-flex justify-content-center w-100"
                  sitekey={process.env.REACT_APP_CAPTCHA}
                  onChange={(e) => setCaptcha(e)}
                /> */}

                <Button
                  type="submit"
                  variant="secondary"
                  disabled={!isValid}
                  className="mt-4 mx-auto"
                >
                  Войти
                </Button>
              </form>
              {/* <div className="custom-telegram-button mt-4 mx-auto">
                <TelegramLoginButton
                  dataOnauth={handleTelegramResponse}
                  botName="BMLPRO1_bot"
                  text="Войти через Телеграм"
                  requestAccess="write"
                  size="large"
                />
              </div> */}
              <Button
                type="submit"
                variant="primary"
                className="mt-4 mx-auto"
                onClick={handleTelegramResponse}
              >
                <FaTelegramPlane className="me-3 fs-18" />
                Войти через Телеграм
              </Button>
              {!isMobile && <p className="mt-2 red">*Авторизация через <Link to="https://web.telegram.org/" className="link" target="_blank">телеграм</Link> возможно только при условии что вы авторизованы  в нём в этом браузере</p>}
              <p className="mt-4">Ещё нет аккаунта? <Link to="/registration" className="link">Регистрация</Link></p>
              <p className="mt-2"><Link to="/password" className="link">Забыли пароль?</Link></p>
            </Col>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Login;
