import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Element, Link } from 'react-scroll';
import SecPartners from '../components/SecPartners';
import BtnFav from '../components/utils/BtnFav';
import { getImageURL, treeAll } from '../helpers/all';

import { FiInfo } from "react-icons/fi";
import { MdCalendarMonth, MdOutlineLocationOn, MdOutlineRemoveRedEye } from "react-icons/md";

import moment from 'moment';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import AdvCard from '../components/AdvCard';
import SwiperNextButton from '../components/utils/SwiperNextButton';
import SwiperPrevButton from '../components/utils/SwiperPrevButton';
import { getProduct } from '../services/product';
import { useDispatch, useSelector } from 'react-redux';
import { getFavorites, toggleFavorite } from '../services/favorite';
import { createTask } from '../services/task';
import Loader from '../components/utils/Loader';
import LeftCard from '../components/LeftCard';
import { useForm, useWatch } from 'react-hook-form';
import Textarea from '../components/utils/Textarea';
import { NotificationManager } from 'react-notifications';
import { getPartner } from '../services/partner';
import { createOrder } from '../services/order';
import Meta from '../components/Meta';



const Offer = () => {
  const { id } = useParams();
  let offsetContents = -140;
  const userId = useSelector(state => state.auth?.user?.id);
  const isAuth = useSelector((state) => state?.auth.isAuth);
  const dataStatus = useSelector((state) => state?.auth?.user?.data?.status);
  const [showAlert, setShowAlert] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const [showWar, setShowWar] = useState(false);
  const handleCloseWar = () => {
    setShowWar(false);
  };
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: { productId: id },
  });
  const data = useWatch({ control });
  const handleSubmitComplaint = () => {
    if (!data?.title) {
      return NotificationManager.error(
        "Небоходимо выбрать тему жалобы"
      )
    }
    setSubmitted(true);
    onTask()
  };
  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const [partner, setPartner] = useState({
    loading: true,
    items: [],
  });

  useEffect(() => {
    getPartner()
      .then(
        (res) =>
          res &&
          setPartner((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .catch(() => setPartner((prev) => ({ ...prev, loading: false })));
  }, []);
  useEffect(() => {
    getProduct({ id })
      .then((res) => {
        setProducts((prev) => ({
          prev,
          loading: false,
          items: res.product,
          banners: res.banners,
          similar: res.similar,
        }))
        setValue("productId", res.product.id);
      })
      .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [id]);
  const dispatch = useDispatch();
  const favorite = useSelector((state) => state.favorite.items);
  useEffect(() => {
    if (isAuth) {
      dispatch(getFavorites());
    }
  }, []);
  const onFav = useCallback(() => {
    dispatch(toggleFavorite({ productId: products.items.id }));
  }, [products]);
  const fav = favorite?.find(el => el?.product?.id == products?.items?.id) ? 1 : 0;
  const onTask = useCallback(() => {
    createTask({ type: "report", ...data })
      .then(() => {
        NotificationManager.success("Жалоба отправлена");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, [data]);
  const onPlan = useCallback(() => {
    createOrder({ productId: products.items.id })
      .then(() => {
        NotificationManager.success("Запрос отправлен");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, [products]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, -1); // Форсируем обновление
    window.scrollTo(0, 0);
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0, behavior: "instant" }); // "instant" вместо "auto" для Firefox
    });
  }, [location]);


  if (products.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Meta title={"Объявление: " + products.items.title + " | " + products?.items?.category?.title} description={products.items.title + " | " + products.items?.data?.city + " | " + products?.items?.category?.title + " | " + products.items?.desc} />
      <Container>
        {/* <NavBreadcrumbs title="Каталог" /> */}
        <section className='page-offer'>
          <h1 className='mb-2'>{products.items.title}</h1>
          {products?.items?.status == -1 ?
            <div className='d-flex mb-2'>
              <h4 className='red me-2 mb-0'>Причина блокировки:</h4>
              <p>{products?.items?.blockedComment}</p>
            </div>
            :
            products?.items?.blockedComment &&
            <div className='d-flex mb-2'>
              <h4 className='red me-2 mb-0'>Причина отклонения:</h4>
              <p>{products?.items?.blockedComment}</p>
            </div>
          }
          <div className="d-lg-flex justify-content-between align-items-center mb-3">
            <h3>{products?.items?.category?.title}</h3>

            <div className="dark-gray extra-font fs-09 d-flex justify-content-start align-items-center mt-3 mt-lg-0">
              <span className='me-3'>ID: {products.items.id}</span>
              <MdCalendarMonth className='fs-12 me-1' />
              <span className='me-3'>{moment(products.items.createdAt).format("DD.MM.YYYY")}</span>
              {/* <MdOutlineRemoveRedEye className='fs-12 me-1' /> */}
              {/* <span className='me-3'>{products.items.view} просмотров</span> */}
            </div>
          </div>

          <Row className='mb-4 mb-sm-5'>
            <Col lg={8}>
              <img src={getImageURL({ path: products.items.cover, size: "max", type: "product/cover" })} alt="франшиза" className='page-offer-cover' />
            </Col>
            <Col lg={4}>
              <div className='page-offer-info'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <NavLink to={"/user/" + products.items?.user?.uid} className='representative'>
                    <img src={getImageURL({ path: products.items?.user?.media, size: "mini", type: "user" })} alt="Александр Васильев" />
                    <div className="flex-1">
                      <h5 className='fw-5'>{products.items?.user?.firstName}.</h5>
                    </div>
                  </NavLink>
                  {userId && products.items?.user?.id != userId && <BtnFav favo={fav} onFav={onFav} />}
                </div>
                {products.items?.categoryId == 1 &&
                  <ul>
                    {products.items?.data?.startingInvestmentsFrom && <li><span>Инвестиции:</span> <strong>{parseInt(products.items?.data?.startingInvestmentsFrom).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.lumpSumPayment && <li><span>Паушальный взнос:</span> <strong>{parseInt(products.items?.data?.lumpSumPayment).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.royalty && <li><span>Роялти:</span> <strong>{parseInt(products.items?.data?.royalty).toLocaleString('ru-RU')}&nbsp;%</strong></li>}
                    {products.items?.data?.paybackPeriodFrom && <li><span>Окупаемость:</span> <strong>{products.items?.data?.paybackPeriodFrom}</strong></li>}
                    {products.items?.data?.estimatedProfit && <li><span>Пред. прибыль:</span> <strong>{parseInt(products.items?.data?.estimatedProfit).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                  </ul>
                }
                {products.items?.categoryId == 2 &&
                  <ul>
                    {products.items?.data?.businessCost && <li><span>Стоимость:</span> <strong>{parseInt(products.items?.data?.businessCost).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.payback && <li><span>Окупаемость:</span> <strong>{products.items?.data?.payback}</strong></li>}
                    {products.items?.data?.turnoverPerMonth && <li><span>Оборот в месяц:</span> <strong>{parseInt(products.items?.data?.turnoverPerMonth).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.netProfit && <li><span>Чистая прибыль:</span> <strong>{parseInt(products.items?.data?.netProfit).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.amountOfPoints && <li><span>Количество точек:</span> <strong>{products.items?.data?.amountOfPoints}</strong></li>}
                    {products.items?.data?.city && <li><span>Город:</span> <strong>{products.items?.data?.city}</strong></li>}
                  </ul>
                }
                {products.items?.categoryId == 3 &&
                  <ul>
                    {products.items?.data?.possibleInvestments && <li><span>Инвестиции:</span> <strong>{parseInt(products.items?.data?.possibleInvestments).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.payback && <li><span>Окупаемость:</span> <strong>{products.items?.data?.payback}</strong></li>}
                    {products.items?.data?.city && <li><span>Город:</span> <strong>{products.items?.data?.city}</strong></li>}
                  </ul>
                }
                {products.items?.categoryId == 4 &&
                  <ul>
                    {products.items?.data?.requiredInvestments && <li><span>Инвестиции:</span> <strong>{parseInt(products.items?.data?.requiredInvestments).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.payback && <li><span>Окупаемость:</span> <strong>{products.items?.data?.payback}</strong></li>}
                    {products.items?.data?.projectStage && <li><span>Стадия проекта:</span> <strong>{products.items?.data?.projectStage}</strong></li>}
                    {products.items?.data?.city && <li><span>Город:</span> <strong>{products.items?.data?.city}</strong></li>}
                  </ul>
                }
                {products.items?.categoryId == 5 &&
                  <ul>
                    {products.items?.data?.requiredInvestments && <li><span>Инвестиции:</span> <strong>{parseInt(products.items?.data?.requiredInvestments).toLocaleString('ru-RU')}&nbsp;Р</strong></li>}
                    {products.items?.data?.payback && <li><span>Окупаемость:</span> <strong>{products.items?.data?.payback}</strong></li>}
                    {products.items?.data?.projectStage && <li><span>Стадия проекта:</span> <strong>{products.items?.data?.projectStage}</strong></li>}
                    {products.items?.data?.city && <li><span>Город:</span> <strong>{products.items?.data?.city}</strong></li>}
                  </ul>
                }
                {userId && userId != products.items?.userId &&
                  (dataStatus ?
                    <>
                      <div className='my-4'>
                        <button type='button' className='btn-info fs-11 w-100 px-3' onClick={() => onPlan()}>ПОЛУЧИТЬ БИЗНЕС-ПЛАН</button>
                        <NavLink to="/account/messages/newMessage" state={products.items?.user} className='btn-light fs-11 w-100 px-3 mt-3'>НАПИСАТЬ СООБЩЕНИЕ</NavLink>
                      </div>
                      <button type='button' className='d-flex align-items-center ms-auto' onClick={() => setShowAlert(true)}>
                        <FiInfo className='fs-13 darker me-2' />
                        <span className='fw-bold fs-09' >Пожаловаться</span>
                      </button>
                    </>
                    :
                    <>
                      <div className='my-4'>
                        <button type='button' className='btn-info fs-11 w-100 px-3' onClick={setShowWar}>ПОЛУЧИТЬ БИЗНЕС-ПЛАН</button>
                        <button type='button' className='btn-light fs-11 w-100 px-3 mt-3' onClick={setShowWar}>НАПИСАТЬ СООБЩЕНИЕ</button>
                      </div>
                      <button type='button' className='d-flex align-items-center ms-auto' onClick={setShowWar}>
                        <FiInfo className='fs-13 darker me-2' />
                        <span className='fw-bold fs-09' >Пожаловаться</span>
                      </button>
                    </>)
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={3} className='d-none d-lg-block'>
              {products.items?.categoryId == 1 &&
                <ul className='list-contents'>

                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-1"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Описание компании
                    </Link>
                  </li>

                  {products.items?.data?.franchiseDesc &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-2"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Описание франшизы
                      </Link>
                    </li>
                  }
                  {products.items?.data?.franchiseBenefits &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-3"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Преимущества франшизы
                      </Link>
                    </li>
                  }
                  {products.items?.data?.termsOfCooperation &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-4"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Условия сотрудничества
                      </Link>
                    </li>
                  }
                  {products.items?.data?.businessPlan &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-5"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Бизнес-план
                      </Link>
                    </li>
                  }

                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-7"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Краткая информация
                    </Link>
                  </li>

                  {products.items?.medias &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-6"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Фотогалерея
                      </Link>
                    </li>
                  }
                  {products?.similar?.length > 0 &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-8"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Похожие объявления
                      </Link>
                    </li>
                  }
                  {products?.banners && products?.banners.length > 0 && products?.banners.map(item =>
                    <li>
                      <LeftCard {...item} />
                    </li>
                  )}
                </ul>
              }
              {products.items?.categoryId == 2 &&
                <ul className='list-contents'>
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-1"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Описание бизнеса
                    </Link>
                  </li>
                  {products.items?.data?.termsOfSale &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-2"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Условия продажи
                      </Link>
                    </li>
                  }
                  {products.items?.data?.businessPlan &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-3"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Бизнес-план
                      </Link>
                    </li>
                  }
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-5"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Краткая информация
                    </Link>
                  </li>

                  {products.items?.medias &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-4"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Фотогалерея
                      </Link>
                    </li>
                  }
                  {products?.similar?.length > 0 &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-8"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Похожие объявления
                      </Link>
                    </li>
                  }
                  {products?.banners && products?.banners.length > 0 && products?.banners.map(item =>
                    <li>
                      <LeftCard {...item} />
                    </li>
                  )}

                </ul>
              }
              {products.items?.categoryId == 3 &&
                <ul className='list-contents'>
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-1"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Описание объявления
                    </Link>
                  </li>
                  {products.items?.data?.estimatedTermsOfCooperation &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-2"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Предполагаемые условия сотрудничества
                      </Link>
                    </li>
                  }
                  {products.items?.data?.about &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-3"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        О себе
                      </Link>
                    </li>
                  }
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-5"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Краткая информация
                    </Link>
                  </li>
                  {products.items?.medias &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-4"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Фотогалерея
                      </Link>
                    </li>
                  }
                  {products?.similar?.length > 0 &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-8"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Похожие объявления
                      </Link>
                    </li>
                  }
                  {products?.banners && products?.banners.length > 0 && products?.banners.map(item =>
                    <li>
                      <LeftCard {...item} />
                    </li>
                  )}
                </ul>
              }
              {products.items?.categoryId == 4 &&
                <ul className='list-contents'>
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-1"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Описание объявления
                    </Link>
                  </li>
                  {products.items?.data?.termsOfCooperation &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-2"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Условия сотрудничества
                      </Link>
                    </li>
                  }
                  {products.items?.data?.businessPlan &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-3"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Бизнес-план
                      </Link>
                    </li>
                  }
                  {products.items?.data?.about &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-4"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        О себе
                      </Link>
                    </li>
                  }
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-6"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Краткая информация
                    </Link>
                  </li>
                  {products.items?.medias &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-5"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Фотогалерея
                      </Link>
                    </li>
                  }
                  {products?.similar?.length > 0 &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-8"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Похожие объявления
                      </Link>
                    </li>
                  }
                  {products?.banners && products?.banners.length > 0 && products?.banners.map(item =>
                    <li>
                      <LeftCard {...item} />
                    </li>
                  )}
                </ul>
              }
              {products.items?.categoryId == 5 &&
                <ul className='list-contents'>
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-1"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Описание объявления
                    </Link>
                  </li>
                  {products.items?.data?.termsOfCooperation &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-2"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Условия сотрудничества
                      </Link>
                    </li>
                  }
                  {products.items?.data?.businessPlan &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-3"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Бизнес-план
                      </Link>
                    </li>
                  }
                  {products.items?.data?.about &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-4"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        О себе
                      </Link>
                    </li>
                  }
                  <li>
                    <Link
                      activeClass="active"
                      to="anchor-6"
                      spy={true}
                      smooth={true}
                      offset={offsetContents}
                      duration={500}
                    >
                      Краткая информация
                    </Link>
                  </li>
                  {products.items?.medias &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-5"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Фотогалерея
                      </Link>
                    </li>
                  }
                  {products?.similar?.length > 0 &&
                    <li>
                      <Link
                        activeClass="active"
                        to="anchor-8"
                        spy={true}
                        smooth={true}
                        offset={offsetContents}
                        duration={500}
                      >
                        Похожие объявления
                      </Link>
                    </li>
                  }
                  {products?.banners && products?.banners.length > 0 && products?.banners.map(item =>
                    <li>
                      <LeftCard {...item} />
                    </li>
                  )}
                </ul>
              }
            </Col>
            <Col lg={9}>
              <hr />
              {products.items?.data?.city &&
                <div className="my-4 dark-gray fs-09 d-flex justify-content-start align-items-center">
                  <MdOutlineLocationOn className='fs-11 color-1 me-1' />
                  <span className='me-3'>Регионы продаж:</span>
                  <span className='black'>{products.items?.data?.city}</span>
                </div>
              }
              {products.items?.categoryId == 1 &&
                <>
                  <Element name="anchor-1" className='chapter'>
                    <h3>Описание компании</h3>
                    <p>{products.items?.desc}</p>
                  </Element>
                  {products.items?.data?.franchiseDesc &&
                    <Element name="anchor-2" className='chapter'>
                      <h3>Описание франшизы</h3>
                      <p>{products.items?.data?.franchiseDesc}</p>
                    </Element>
                  }
                  {products.items?.data?.franchiseBenefits &&
                    <Element name="anchor-3" className='chapter'>
                      <h3>Преимущества франшизы</h3>
                      <p>{products.items?.data?.franchiseBenefits}</p>
                    </Element>
                  }
                  {products.items?.data?.termsOfCooperation &&
                    <Element name="anchor-4" className='chapter'>
                      <h3>Условия сотрудничества</h3>
                      <p>{products.items?.data?.termsOfCooperation}</p>
                    </Element>
                  }
                  {products.items?.data?.businessPlan &&
                    <Element name="anchor-5" className='chapter'>
                      <h3>Бизнес-план </h3>
                      <p>{products.items?.data?.businessPlan}</p>
                    </Element>
                  }

                  <Element name="anchor-6" className='chapter'>
                    <h3>Фотогалерея</h3>
                    <Row xs={3} md={4} className='g-2 g-sm-3'>
                      {products?.items?.medias && products.items.medias.length > 0 && products.items.medias.map(item =>
                        <Col>
                          <img src={getImageURL({ path: item.media, size: "mini", type: "product/media" })} alt="франшиза" className='img-fluid' onClick={() => setShowShare(item.media)} />
                        </Col>
                      )}
                    </Row>
                  </Element>

                  <Element name="anchor-7" className='chapter'>
                    <h3>Краткая информация</h3>
                    <ul>
                      {products?.items?.data?.video && <li>Видео:  {products?.items?.data?.video}</li>}
                      {products?.items?.data?.startingInvestmentsFrom && <li>Стартовые инвестиции от: {products?.items?.data?.startingInvestmentsFrom}&nbsp;₽</li>}
                      {products?.items?.data?.lumpSumPayment && <li>Паушальный взнос: {products?.items?.data?.lumpSumPayment}&nbsp;₽</li>}
                      {products?.items?.data?.royalty && <li>Роялти: {products?.items?.data?.royalty}&nbsp;%</li>}
                      {products?.items?.data?.paybackPeriodFrom && <li>Срок окупаемости от: {products?.items?.data?.paybackPeriodFrom}</li>}
                      {products?.items?.data?.estimatedProfit && <li>Предполагаемая прибыль: {products?.items?.data?.estimatedProfit}&nbsp;₽</li>}
                      {products?.items?.data?.yearTheCompanyWasFounded && <li>Год основания компании: {products?.items?.data?.yearTheCompanyWasFounded}</li>}
                      {products?.items?.data?.numberOfOwnPoints && <li>Количество собственных точек: {products?.items?.data?.numberOfOwnPoints}</li>}
                      {products?.items?.data?.numberOfFranchisesSold && <li>Количество проданных франшиз:{products?.items?.data?.numberOfFranchisesSold}</li>}
                    </ul>
                    {/* <button type='button' className='d-flex align-items-center mt-5'>
                      <FiInfo className='fs-13 darker me-2' />
                      <span className='fw-bold fs-09'>Пожаловаться</span>
                      <span className='text-danger fw-medium fs-09 ms-2'>Вы пожаловались</span>
                    </button> */}
                  </Element>
                </>
              }
              {products.items?.categoryId == 2 &&
                <>
                  <Element name="anchor-1" className='chapter'>
                    <h3>Описание бизнеса</h3>
                    <p>{products.items?.desc}</p>
                  </Element>
                  {products.items?.data?.termsOfSale &&
                    <Element name="anchor-2" className='chapter'>
                      <h3>Условия продажи</h3>
                      <p>{products.items?.data?.termsOfSale}</p>
                    </Element>
                  }
                  {products.items?.data?.businessPlan &&
                    <Element name="anchor-3" className='chapter'>
                      <h3>Бизнес-план </h3>
                      <p>{products.items?.data?.businessPlan}</p>
                    </Element>
                  }

                  <Element name="anchor-4" className='chapter'>
                    <h3>Фотогалерея</h3>
                    <Row xs={3} md={4} className='g-2 g-sm-3'>
                      {products?.items?.medias && products.items.medias.length > 0 && products.items.medias.map(item =>
                        <Col>
                          <img src={getImageURL({ path: item.media, size: "mini", type: "product/media" })} alt="франшиза" className='img-fluid' onClick={() => setShowShare(item.media)} />
                        </Col>
                      )}
                    </Row>
                  </Element>

                  <Element name="anchor-5" className='chapter'>
                    <h3>Краткая информация</h3>
                    <ul>
                      {products?.items?.data?.amountOfPoints && <li>Количество точек:  {products?.items?.data?.amountOfPoints}</li>}
                      {products?.items?.data?.businessCost && <li>Стоимость бизнеса: {products?.items?.data?.businessCost}&nbsp;₽</li>}
                      {products?.items?.data?.payback && <li>Окупаемость: {products?.items?.data?.payback}</li>}
                      {products?.items?.data?.turnoverPerMonth && <li>Оборот в месяц: {products?.items?.data?.turnoverPerMonth}&nbsp;₽</li>}
                      {products?.items?.data?.netProfit && <li>Чистая прибыль: {products?.items?.data?.netProfit}&nbsp;₽</li>}
                    </ul>
                    {/* <button type='button' className='d-flex align-items-center mt-5'>
                      <FiInfo className='fs-13 darker me-2' />
                      <span className='fw-bold fs-09'>Пожаловаться</span>
                      <span className='text-danger fw-medium fs-09 ms-2'>Вы пожаловались</span>
                    </button> */}
                  </Element>
                </>
              }
              {products.items?.categoryId == 3 &&
                <>
                  <Element name="anchor-1" className='chapter'>
                    <h3>Описание объявления</h3>
                    <p>{products.items?.desc}</p>
                  </Element>
                  {products.items?.data?.estimatedTermsOfCooperation &&
                    <Element name="anchor-2" className='chapter'>
                      <h3>Предполагаемые условия сотрудничества</h3>
                      <p>{products.items?.data?.estimatedTermsOfCooperation}</p>
                    </Element>
                  }
                  {products.items?.data?.about &&
                    <Element name="anchor-3" className='chapter'>
                      <h3>О себе </h3>
                      <p>{products.items?.data?.about}</p>
                    </Element>
                  }

                  <Element name="anchor-4" className='chapter'>
                    <h3>Фотогалерея</h3>
                    <Row xs={3} md={4} className='g-2 g-sm-3'>
                      {products?.items?.medias && products.items.medias.length > 0 && products.items.medias.map(item =>
                        <Col>
                          <img src={getImageURL({ path: item.media, size: "mini", type: "product/media" })} alt="франшиза" className='img-fluid' onClick={() => setShowShare(item.media)} />
                        </Col>
                      )}
                    </Row>
                  </Element>

                  <Element name="anchor-5" className='chapter'>
                    <h3>Краткая информация</h3>
                    <ul>
                      {products?.items?.data?.possibleInvestments && <li>Возможные инвестиции:  {products?.items?.data?.possibleInvestments}</li>}
                      {products?.items?.data?.payback && <li>Окупаемость: {products?.items?.data?.payback}&nbsp;₽</li>}
                    </ul>
                    {/* <button type='button' className='d-flex align-items-center mt-5'>
                      <FiInfo className='fs-13 darker me-2' />
                      <span className='fw-bold fs-09'>Пожаловаться</span>
                      <span className='text-danger fw-medium fs-09 ms-2'>Вы пожаловались</span>
                    </button> */}
                  </Element>
                </>
              }
              {products.items?.categoryId == 4 &&
                <>
                  <Element name="anchor-1" className='chapter'>
                    <h3>Описание объявления</h3>
                    <p>{products.items?.desc}</p>
                  </Element>
                  {products.items?.data?.termsOfCooperation &&
                    <Element name="anchor-2" className='chapter'>
                      <h3>Условия сотрудничества</h3>
                      <p>{products.items?.data?.termsOfCooperation}</p>
                    </Element>
                  }
                  {products.items?.data?.businessPlan &&
                    <Element name="anchor-3" className='chapter'>
                      <h3>Бизнес-план </h3>
                      <p>{products.items?.data?.businessPlan}</p>
                    </Element>
                  }
                  {products.items?.data?.about &&
                    <Element name="anchor-4" className='chapter'>
                      <h3>О себе </h3>
                      <p>{products.items?.data?.about}</p>
                    </Element>
                  }

                  <Element name="anchor-5" className='chapter'>
                    <h3>Фотогалерея</h3>
                    <Row xs={3} md={4} className='g-2 g-sm-3'>
                      {products?.items?.medias && products.items.medias.length > 0 && products.items.medias.map(item =>
                        <Col>
                          <img src={getImageURL({ path: item.media, size: "mini", type: "product/media" })} alt="франшиза" className='img-fluid' onClick={() => setShowShare(item.media)} />
                        </Col>
                      )}
                    </Row>
                  </Element>

                  <Element name="anchor-6" className='chapter'>
                    <h3>Краткая информация</h3>
                    <ul>
                      {products?.items?.data?.requiredInvestments && <li>Требуемые инвестиции:  {products?.items?.data?.requiredInvestments}&nbsp;₽</li>}
                      {products?.items?.data?.estimatedProfitMeme && <li>Предполагаемая прибыль / мес: {products?.items?.data?.estimatedProfitMeme}&nbsp;₽</li>}
                      {products?.items?.data?.payback && <li>Окупаемость: {products?.items?.data?.payback}</li>}
                      {products?.items?.data?.projectStage && <li>Стадия проекта:  {products?.items?.data?.projectStage}</li>}
                    </ul>
                    {/* <button type='button' className='d-flex align-items-center mt-5'>
                      <FiInfo className='fs-13 darker me-2' />
                      <span className='fw-bold fs-09'>Пожаловаться</span>
                      <span className='text-danger fw-medium fs-09 ms-2'>Вы пожаловались</span>
                    </button> */}
                  </Element>
                </>
              }
              {products.items?.categoryId == 5 &&
                <>
                  <Element name="anchor-1" className='chapter'>
                    <h3>Описание объявления</h3>
                    <p>{products.items?.desc}</p>
                  </Element>
                  {products.items?.data?.termsOfCooperation &&
                    <Element name="anchor-2" className='chapter'>
                      <h3>Условия сотрудничества</h3>
                      <p>{products.items?.data?.termsOfCooperation}</p>
                    </Element>
                  }
                  {products.items?.data?.businessPlan &&
                    <Element name="anchor-3" className='chapter'>
                      <h3>Бизнес-план </h3>
                      <p>{products.items?.data?.businessPlan}</p>
                    </Element>
                  }
                  {products.items?.data?.about &&
                    <Element name="anchor-4" className='chapter'>
                      <h3>О себе </h3>
                      <p>{products.items?.data?.about}</p>
                    </Element>
                  }

                  <Element name="anchor-5" className='chapter'>
                    <h3>Фотогалерея</h3>
                    <Row xs={3} md={4} className='g-2 g-sm-3'>
                      {products?.items?.medias && products.items.medias.length > 0 && products.items.medias.map(item =>
                        <Col>
                          <img src={getImageURL({ path: item.media, size: "mini", type: "product/media" })} alt="франшиза" className='img-fluid' onClick={() => setShowShare(item.media)} />
                        </Col>
                      )}
                    </Row>
                  </Element>

                  <Element name="anchor-6" className='chapter'>
                    <h3>Краткая информация</h3>
                    <ul>
                      {products?.items?.data?.requiredInvestments && <li>Требуемые инвестиции:  {products?.items?.data?.requiredInvestments}&nbsp;₽</li>}
                      {products?.items?.data?.estimatedProfitMeme && <li>Предполагаемая прибыль / мес: {products?.items?.data?.estimatedProfitMeme}&nbsp;₽</li>}
                      {products?.items?.data?.payback && <li>Окупаемость: {products?.items?.data?.payback}</li>}
                      {products?.items?.data?.projectStage && <li>Стадия проекта:  {products?.items?.data?.projectStage}</li>}
                    </ul>
                    {/* <button type='button' className='d-flex align-items-center mt-5'>
                      <FiInfo className='fs-13 darker me-2' />
                      <span className='fw-bold fs-09'>Пожаловаться</span>
                      <span className='text-danger fw-medium fs-09 ms-2'>Вы пожаловались</span>
                    </button> */}
                  </Element>
                </>
              }
              <div className='chapter'>
                <ul>
                  {products?.items?.category?.params && products?.items?.category?.params.length > 0 && [...products?.items?.category?.params].sort((a, b) => a.priority - b.priority).map((param, i) => {
                    let spanOpt = treeAll(products?.items?.options[i], param?.options);
                    if (spanOpt && products?.items?.options[i]) {
                      return (
                        <li><span>{param?.title}</span>
                          {spanOpt && spanOpt.length > 0 && spanOpt.map(item => {
                            return (
                              <>
                                <span className='me-2'>,</span>
                                <span>{item?.title}</span>
                              </>
                            )
                          })}
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            </Col>
          </Row>
          {products?.similar?.length > 0 &&
            <Element name="anchor-8" className='chapter my-3 my-sm-4 my-md-5'>
              <h2>Похожие ОБЪЯВЛЕНИЯ</h2>
              <div className="position-relative">
                <Swiper
                  className='position-static'
                  modules={[Navigation]}
                  loop={true}
                  navigation={{
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                  }}
                  spaceBetween={8}
                  slidesPerView={2}
                  breakpoints={{
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 15
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 30
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 30
                    }
                  }}
                >
                  {products.similar.map(product =>
                    <SwiperSlide>
                      <AdvCard key={product?.id} {...product} onFav={onFav} />
                    </SwiperSlide>
                  )}
                  <SwiperPrevButton />
                  <SwiperNextButton />
                </Swiper>
              </div>
            </Element>
          }
        </section>
      </Container>

      <SecPartners partner={partner} />
      <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="mb-3">Пожаловаться</h4>
          {!submitted ? (
            <div>
              <div className="mb-4">
                <select value={data.title} onChange={e => setValue('title', e.target.value)} className="mb-3">
                  <option value="">Выберите тему жалобы</option>
                  <option value="Спам">Спам</option>
                  <option value="Мошенники">Мошенники</option>
                  <option value="Объявления уже не актуально">Объявления уже не актуально</option>
                  <option value="Неверная цена">Неверная цена</option>
                  <option value="Неверное описание и фото">Неверное описание и фото</option>
                  <option value="Нецензурная лексика">Нецензурная лексика</option>
                  <option value="Неблагоприятный контент">Неблагоприятный контент</option>
                  <option value="Неверный раздел">Неверный раздел</option>
                  <option value="Нарушает правила BML">Нарушает правила BML</option>
                  <option value="Другое">Другое</option>
                </select>
                <Textarea
                  className="col-md-6"
                  type={"text"}
                  label={"Описание"}
                  onChange={e => setValue("comment", e)}
                />
              </div>
              <div className="d-flex justify-content-between">
                <Button onClick={handleSubmit(handleSubmitComplaint)} className="mr-3">Отправить жалобу</Button>
                <Button onClick={handleCloseAlert} className="mr-3">Закрыть</Button>
              </div>
            </div>
          ) : (
            <div className="mb-3 text-success">
              Жалоба отправлена!
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showShare} onHide={setShowShare} centered size="xl" className='modal'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='modal-dialog'>
          <img src={getImageURL({ path: showShare, size: "", type: "product/media" })} onClick={setShowShare} />
        </Modal.Body>
      </Modal>


      <Modal show={showWar} onHide={handleCloseWar} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="mb-4">Размещение объявлений и полный доступ к сайту будет доступен после заполнения информации профиля</h4>

          <div>
            <div className="d-flex justify-content-between">
              <Button className="mr-3" onClick={() => navigate("/account/settings")}>Заполнить профиль</Button>
              <Button onClick={handleCloseWar} className="mr-3">Закрыть</Button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </main >
  );
};

export default Offer;