import React from 'react';
import { Link } from 'react-router-dom';
import useIsMobile from '../hooks/isMobile';
import { getImageURL } from '../helpers/all';

const BlogPartner = ({ id, media, title, desc, link }) => {
  const isMobileLG = useIsMobile('1109px');
  const image = getImageURL({ path: media, size: "", type: "instruction" })
  return (
    <Link to={link}>
      <figure className="info-partners">
        <img src={image} alt={title} />
        <figcaption>
          <h6>{title}</h6>
          <div className="info-partners-text" dangerouslySetInnerHTML={{ __html: desc }}>
          </div>
        </figcaption>
      </figure>
    </Link>
  );
};

export default BlogPartner;