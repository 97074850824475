import React from 'react';
import moment from "moment";
import WarningIcon from '../svg/WarningIcon';

import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { getImageURL } from '../../helpers/all';


const Message = ({ text, user, createdAt, memberId, view = false }) => {
  const userId = useSelector((state) => state.auth?.user?.id);
  const time = createdAt
    ? moment(createdAt).format("DD.MM.YYYY kk:mm")
    : moment().format("DD.MM.YYYY kk:mm");
  return userId == user?.id ? (
    <div className="chat-window-message-mine">
      <div className='text'>
        <div className='fs-08 d-flex align-items-center mb-2'>
          <h6 className="me-2 fs-11 dark-gray">{user.firstName}</h6>
          <time className='gray me-2'>
            {time}
          </time>
          {/* {view ? (
            <IoCheckmarkDoneOutline className="text-success ms-1" size={15} />
          ) : (
            <IoCheckmarkOutline className="ms-1" size={15} />
          )} */}
        </div>
        <div className="bubble">
          <p>{text}</p>
        </div>
      </div>
      <img src={getImageURL({ path: user.media })} alt={name} />
    </div>
  ) : (
    memberId ? (
      <div className="chat-window-message">
        <img src="/imgs/user.jpg" alt={name} />
        <div className='text'>
          <div className='fs-08 d-flex align-items-center mb-2'>
            <h6 className="me-2 fs-12">Admin </h6>
            <time className='gray me-2'> {time}</time>
          </div>
          <div className="bubble">
            <p>{text}</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="chat-window-message">
        <img src={getImageURL({ path: user.media })} alt={name} />
        <div className='text'>
          <div className='fs-08 d-flex align-items-center mb-2'>
            <h6 className="me-2 fs-11 dark-gray">{user.firstName}</h6>
            <time className='gray me-2'>{time}</time>
          </div>
          <div className="bubble">
            <p>{text}</p>
          </div>
        </div>
      </div>
    )
  )
};
export default Message;